/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Person from "@material-ui/icons/Person";
import {
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
} from "@material-ui/core";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardText = lazy(() => import("components/Card/CardText.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import { GenderList } from "constant/Data";

import styles from "./CustomerStyle.js";

class ManageCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEdit: (props.location.state && props.location.state.edit) || false,
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      contactNumber: "",
      gender: "",
      isSuspend: false,
      isTerminate: false,
      isActivate: false,
    };
  }

  componentDidMount() {
    const { fromEdit } = this.state;
    fromEdit &&
      this.setState({
        firstName: "Alex",
        lastName: "Mike",
        email: "alexMike@gmail.com",
        address:
          "MARY ROE MEGASYSTEMS INC SUITE 5A-1204 799 E DRAGRAM TUCSON AZ 85705 USA",
        contactNumber: "+1-364-785-5114",
        gender: "Male",
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeActivate = () => {
    this.setState((prevState) => ({
      isActivate: !prevState.isActivate,
    }));
  };

  handleChangeTerminate = () => {
    this.setState((prevState) => ({
      isTerminate: !prevState.isTerminate,
    }));
  };

  handleChangeSuspend = () => {
    this.setState((prevState) => ({
      isSuspend: !prevState.isSuspend,
    }));
  };

  onSubmit = () => {
    this.props.history.push("customer-list", { showToaster: true });
  };

  render() {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      email,
      address,
      gender,
      contactNumber,
      isSuspend,
      isTerminate,
      isActivate,
    } = this.state;

    return (
      <>
        <Suspense fallback={<div />}>
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("First Name is required"),
              lastName: Yup.string().required("Last Name is required"),
              email: Yup.string()
                .required("Email is required")
                .email("Email is invalid"),
              address: Yup.string().required("Address is required"),
              gender: Yup.string().required("Gender is required"),
              contactNumber: Yup.string().required(
                "Contact Number is required"
              ),
            })}
            initialValues={{
              firstName,
              lastName,
              email,
              address,
              gender,
              contactNumber,
            }}
            onSubmit={(values) => {
              this.onSubmit(values);
            }}
          >
            {({ errors, values, touched, handleSubmit }) => (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <div className={classes.titleSpacing}>
                          <Person className={classes.titleIcon} />
                          <h4 className={classes.cardTitle}>Manage Customer</h4>
                        </div>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              First Name
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="firstName"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              placeholder="Enter First Name"
                              variant="outlined"
                              margin="normal"
                              value={values.firstName}
                              onChange={this.handleChange}
                              disabled
                            />
                            {errors.firstName && touched.firstName && (
                              <FormHelperText error>
                                {errors.firstName}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Last Name
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="lastName"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              placeholder="Enter Last Name"
                              variant="outlined"
                              margin="normal"
                              value={values.lastName}
                              onChange={this.handleChange}
                              disabled
                            />
                            {errors.lastName && touched.lastName && (
                              <FormHelperText error>
                                {errors.lastName}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Email
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="email"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              variant="outlined"
                              placeholder="Enter Email"
                              type="email"
                              margin="normal"
                              value={values.email}
                              onChange={this.handleChange}
                              disabled
                            />
                            {errors.email && touched.email && (
                              <FormHelperText error>
                                {errors.email}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Contact Number
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="contactNumber"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              variant="outlined"
                              placeholder="Enter Contact Number"
                              margin="normal"
                              value={values.contactNumber}
                              onChange={this.handleChange}
                              disabled
                            />
                            {errors.contactNumber && touched.contactNumber && (
                              <FormHelperText error>
                                {errors.contactNumber}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Gender
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="gender"
                              select
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              placeholder="Select Gender"
                              variant="outlined"
                              margin="normal"
                              value={values.gender}
                              onChange={this.handleChange}
                              disabled
                            >
                              {GenderList.map((val, index) => (
                                <MenuItem key={val + index} value={val}>
                                  {val}
                                </MenuItem>
                              ))}
                            </TextField>
                            {errors.gender && touched.gender && (
                              <FormHelperText error>
                                {errors.gender}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Address
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="address"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              variant="outlined"
                              placeholder="Enter Address"
                              type="text"
                              margin="normal"
                              multiline
                              rows={4}
                              value={values.address}
                              onChange={this.handleChange}
                              disabled
                            />
                            {errors.address && touched.address && (
                              <FormHelperText error>
                                {errors.address}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.customerStatusSection}
                        >
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle +
                                " " +
                                classes.customerLabel
                              }
                            >
                              Suspend
                            </FormLabel>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={10}
                            className={classes.customSwitch}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isSuspend}
                                  onChange={this.handleChangeSuspend}
                                  value="isSuspended"
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.customerStatusSection}
                        >
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle +
                                " " +
                                classes.customerLabel
                              }
                            >
                              Terminate
                            </FormLabel>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={10}
                            className={classes.customSwitch}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isTerminate}
                                  onChange={this.handleChangeTerminate}
                                  value="isTerminate"
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.customerStatusSection}
                        >
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle +
                                " " +
                                classes.customerLabel
                              }
                            >
                              Activate
                            </FormLabel>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={10}
                            className={classes.customSwitch}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isActivate}
                                  onChange={this.handleChangeActivate}
                                  value="isActivate"
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12}>
                            <div style={{ float: "right" }}>
                              <Button color="info" onClick={handleSubmit}>
                                Save
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </Formik>
        </Suspense>
      </>
    );
  }
}

ManageCustomer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageCustomer);
