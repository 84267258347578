/*eslint-disable*/
import React, { lazy, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import { TextField, Checkbox, FormHelperText } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const CardFooter = lazy(() => import("components/Card/CardFooter.js"));

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const checked = true;
  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  const goToDashboard = () => {
    props.history.push("admin/dashboard");
  };

  return (
    <>
      <Suspense fallback={<div />}>
        <div className={classes.loginBackground + " " + classes.loginContent}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <Formik
                  enableReinitialize={true}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().required("Email is required"),
                    password: Yup.string().required("Password is required"),
                  })}
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  onSubmit={(values) => {
                    goToDashboard();
                  }}
                >
                  {({
                    errors,
                    values,
                    touched,
                    handleSubmit,
                    handleChange,
                  }) => (
                    <form className={classes.loginForm} onSubmit={handleSubmit}>
                      <Card login className={classes[cardAnimation]}>
                        <div className={classes.userImgDiv}>
                          <div className={classes.userImg}>
                            <img
                              src={require("../../assets/img/user.svg")}
                              alt=""
                            />
                          </div>
                        </div>

                        <CardBody className={classes.logInCardBody}>
                          <div className={classes.logInTitle}>Log In</div>

                          <div className={classes.nameField}>
                            <TextField
                              required
                              id="email"
                              label="Email..."
                              value={values.email}
                              onChange={handleChange}
                              className={classes.firstName}
                            />
                            <Email className={classes.faceIcon} />
                          </div>

                          {errors.email && touched.email && (
                            <FormHelperText error>
                              {errors.email}
                            </FormHelperText>
                          )}

                          <div className={classes.nameField}>
                            <TextField
                              required
                              id="password"
                              label="Password"
                              type="password"
                              value={values.password}
                              onChange={handleChange}
                              className={classes.firstName}
                            />
                            <Icon className={classes.faceIcon}>
                              lock_outline
                            </Icon>
                          </div>
                          {errors.password && touched.password && (
                            <FormHelperText error>
                              {errors.password}
                            </FormHelperText>
                          )}
                        </CardBody>
                        <CardFooter
                          className={
                            classes.justifyContentCenter +
                            " " +
                            classes.footerSpacing
                          }
                        >
                          <Button
                            className={classes.loginButton}
                            type="submit"
                            onClick={handleSubmit}
                          >
                            {" "}
                            LET'S GO{" "}
                          </Button>
                          <div className={classes.rememberPassword}>
                            <div className={classes.rememberMe}>
                              <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />{" "}
                              Remember me{" "}
                            </div>
                          </div>
                        </CardFooter>
                      </Card>
                    </form>
                  )}
                </Formik>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </Suspense>
    </>
  );
}
