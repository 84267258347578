/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField, FormHelperText } from "@material-ui/core";
import Person from "@material-ui/icons/Person";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardText = lazy(() => import("components/Card/CardText.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import styles from "assets/jss/material-dashboard-pro-react/views/userManagementStyle";

class ManageCustomerService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEdit: (props.location.state && props.location.state.edit) || false,
      ticketNo: "",
      ticketType: "",
      ticketDescription: "",
      answer: "",
    };
  }

  componentDidMount() {
    const { fromEdit } = this.state;
    fromEdit &&
      this.setState({
        ticketNo: 101,
        ticketType: "Query",
        ticketDescription: "Query",
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = () => {
    this.props.history.push("customer-service-list", { showToaster: true });
  };

  render() {
    const { classes } = this.props;
    const { ticketNo, ticketType, ticketDescription, answer } = this.state;

    return (
      <>
        <Suspense fallback={<div />}>
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              answer: Yup.string().required("Service Name is required"),
            })}
            initialValues={{
              answer,
            }}
            onSubmit={(values) => {
              this.onSubmit(values);
            }}
          >
            {({ errors, values, touched, handleSubmit }) => (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <div className={classes.divFlex}>
                          <Person />
                          <h4 className={classes.cardTitle}>
                            Manage Customer Service
                          </h4>
                        </div>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Ticket Number
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="ticketNo"
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              value={ticketNo}
                              onChange={this.handleChange}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Ticket Type
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="ticketType"
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              value={ticketType}
                              onChange={this.handleChange}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Ticket Description
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="ticketDescription"
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              multiline
                              rowsMax={7}
                              rows={4}
                              value={ticketDescription}
                              onChange={this.handleChange}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Answer from Admin
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              label="Enter Your Answer Here"
                              name="answer"
                              fullWidth
                              variant="outlined"
                              multiline
                              rowsMax={7}
                              rows={4}
                              margin="normal"
                              value={values.answer}
                              onChange={this.handleChange}
                            />
                            {errors.answer && touched.answer && (
                              <FormHelperText error>
                                {errors.answer}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12}>
                            <div style={{ float: "right" }}>
                              <Button color="info" onClick={handleSubmit}>
                                Save
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </Formik>
        </Suspense>
      </>
    );
  }
}

ManageCustomerService.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageCustomerService);
