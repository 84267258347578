/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField, MenuItem, FormHelperText } from "@material-ui/core";
import Notes from "@material-ui/icons/Notes";
import { Formik } from "formik";
import * as Yup from "yup";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardText = lazy(() => import("components/Card/CardText.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import { GenderList, UserTypeOption, RestrictionOptions } from "constant/Data";

import styles from "assets/jss/material-dashboard-pro-react/views/userManagementStyle";

class ManageAffiliateService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEdit: (props.location.state && props.location.state.edit) || false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      gender: "",
      userType: "",
      restrictFrom: "",
    };
  }

  componentDidMount() {
    const { fromEdit } = this.state;
    fromEdit &&
      this.setState({
        firstName: "Andrew",
        lastName: "Mike",
        email: "andrewMike@gmail.com",
        phoneNumber: "+1-541-754-3010",
        gender: "Male",
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = () => {
    console.log(this.state, "state");
    this.props.history.push("affiliate-service-list", { showToaster: true });
  };

  render() {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      email,
      gender,
      phoneNumber,
      userType,
      restrictFrom,
    } = this.state;

    return (
      <>
        <Suspense fallback={<div />}>
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("First Name is required"),
              lastName: Yup.string().required("Last Name is required"),
              email: Yup.string()
                .email("Email is invalid")
                .required("Email is required"),
              gender: Yup.string().required("Gender is required"),
              phoneNumber: Yup.string().required("Phone Number is required"),
              userType: Yup.string().required("User Type is required"),
            })}
            initialValues={{
              firstName,
              lastName,
              email,
              gender,
              phoneNumber,
              userType,
              restrictFrom,
            }}
            onSubmit={(values) => {
              this.onSubmit(values);
            }}
          >
            {({ errors, values, touched, handleSubmit }) => (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <div className={classes.divFlex}>
                          <Notes />
                          <h4 className={classes.cardTitle}>Manage User</h4>
                        </div>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              First Name
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="firstName"
                              fullWidth
                              placeholder="Enter First Name"
                              variant="outlined"
                              margin="normal"
                              value={values.firstName}
                              onChange={this.handleChange}
                            />
                            {errors.firstName && touched.firstName && (
                              <FormHelperText error>
                                {errors.firstName}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Last Name
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="lastName"
                              fullWidth
                              placeholder="Enter Last Name"
                              variant="outlined"
                              margin="normal"
                              value={values.lastName}
                              onChange={this.handleChange}
                            />
                            {errors.lastName && touched.lastName && (
                              <FormHelperText error>
                                {errors.lastName}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Email
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="email"
                              fullWidth
                              variant="outlined"
                              placeholder="Enter Email"
                              type="email"
                              margin="normal"
                              value={values.email}
                              onChange={this.handleChange}
                            />
                            {errors.email && touched.email && (
                              <FormHelperText error>
                                {errors.email}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Phone Number
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="phoneNumber"
                              fullWidth
                              variant="outlined"
                              placeholder="Enter Phone Number"
                              margin="normal"
                              value={values.phoneNumber}
                              onChange={this.handleChange}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <FormHelperText error>
                                {errors.phoneNumber}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Gender
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="gender"
                              select
                              fullWidth
                              placeholder="Select Gender"
                              variant="outlined"
                              margin="normal"
                              value={values.gender}
                              onChange={this.handleChange}
                            >
                              {GenderList.map((val, index) => (
                                <MenuItem key={val + index} value={val}>
                                  {val}
                                </MenuItem>
                              ))}
                            </TextField>
                            {errors.gender && touched.gender && (
                              <FormHelperText error>
                                {errors.gender}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              User Type
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="userType"
                              select
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              value={values.userType}
                              onChange={this.handleChange}
                            >
                              {UserTypeOption.map((val, index) => (
                                <MenuItem key={val + index} value={val}>
                                  {val}
                                </MenuItem>
                              ))}
                            </TextField>
                            {errors.userType && touched.userType && (
                              <FormHelperText error>
                                {errors.userType}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Restricted From
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="restrictFrom"
                              select
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              value={restrictFrom}
                              onChange={this.handleChange}
                            >
                              {RestrictionOptions.map((val, index) => (
                                <MenuItem key={val + index} value={val}>
                                  {val}
                                </MenuItem>
                              ))}
                            </TextField>
                            {errors.restrictFrom && touched.restrictFrom && (
                              <FormHelperText error>
                                {errors.restrictFrom}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12}>
                            <div style={{ float: "right" }}>
                              <Button color="info" onClick={handleSubmit}>
                                Save
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </Formik>
        </Suspense>
      </>
    );
  }
}

ManageAffiliateService.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageAffiliateService);
