/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Person from "@material-ui/icons/Person";
import {
  TextField,
  MenuItem,
  FormHelperText,
  FormControl,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardText = lazy(() => import("components/Card/CardText.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import { myServiceList } from "./data";
import { GenderList, DeliveryPersonTypeOptions } from "constant/Data";

import styles from "./DeliveryPartnerStyle.js";

class ManageDeliveryPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEdit: (props.location.state && props.location.state.edit) || false,
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      phoneNumber: "",
      gender: "",
      deliveryPersonType: "",
      workingStartTime: "",
      WorkingEndTime: "",
      WorkingDays: "",
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      experience: "",
      totalOrders: "",
    };
  }

  componentDidMount() {
    const { fromEdit } = this.state;
    fromEdit &&
      this.setState({
        firstName: "Andrew",
        lastName: "Mike",
        email: "andrewMike@gmail.com",
        address:
          "MARY ROE MEGASYSTEMS INC SUITE 5A-1204 799 E DRAGRAM TUCSON AZ 85705 USA",
        phoneNumber: "+1-675-754-3010	",
        gender: "Male",
        deliveryPersonType: "House Cleaner",
        workingStartTime: "10 AM",
        WorkingEndTime: "5 PM",
        experience: "5 Years",
        totalOrders: "50",
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChecked = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  onSubmit = () => {
    this.props.history.push("delivery-partner-list", { showToaster: true });
  };

  render() {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      email,
      address,
      gender,
      phoneNumber,
      genderOption,
      deliveryPersonType,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      workingStartTime,
      WorkingEndTime,
      experience,
      totalOrders,
    } = this.state;

    const error =
      [monday, tuesday, wednesday, thursday, friday, saturday, sunday].filter(
        (v) => v
      ).length !== 2;

    const localizer = momentLocalizer(moment);

    return (
      <>
        <Suspense fallback={<div />}>
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("First Name is required"),
              lastName: Yup.string().required("Last Name is required"),
              email: Yup.string()
                .required("Email is required")
                .email("Email is Invalid"),
              address: Yup.string().required("Address is required"),
              gender: Yup.string().required("Gender is required"),
              phoneNumber: Yup.string().required("Phone Number is required"),
              deliveryPersonType: Yup.string().required(
                "Delivery person type is required"
              ),
              workingStartTime: Yup.string().required(
                "Working Start Time is required"
              ),
              WorkingEndTime: Yup.string().required(
                "Working Start Time is required"
              ),
            })}
            initialValues={{
              firstName,
              lastName,
              email,
              address,
              gender,
              phoneNumber,
              deliveryPersonType,
              workingStartTime,
              WorkingEndTime,
              experience,
              totalOrders,
            }}
            onSubmit={(values) => {
              this.onSubmit(values);
            }}
          >
            {({ errors, values, touched, handleSubmit }) => (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <div className={classes.titleSpacing}>
                          <Person className={classes.titleIcon} />
                          <h4 className={classes.cardTitle}>
                            Manage Delivery Partner
                          </h4>
                        </div>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              First Name
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="firstName"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              placeholder="Enter First Name"
                              variant="outlined"
                              margin="normal"
                              value={values.firstName}
                              onChange={this.handleChange}
                            />
                            {errors.firstName && touched.firstName && (
                              <FormHelperText error>
                                {errors.firstName}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Last Name
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="lastName"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              placeholder="Enter Last Name"
                              variant="outlined"
                              margin="normal"
                              value={values.lastName}
                              onChange={this.handleChange}
                            />
                            {errors.lastName && touched.lastName && (
                              <FormHelperText error>
                                {errors.lastName}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Email
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="email"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              variant="outlined"
                              placeholder="Enter Email"
                              type="email"
                              margin="normal"
                              value={values.email}
                              onChange={this.handleChange}
                            />
                            {errors.email && touched.email && (
                              <FormHelperText error>
                                {errors.email}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Phone Number
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="phoneNumber"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              variant="outlined"
                              placeholder="Enter Phone Number"
                              margin="normal"
                              value={values.phoneNumber}
                              onChange={this.handleChange}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <FormHelperText error>
                                {errors.phoneNumber}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Gender
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="gender"
                              select
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              placeholder="Select Gender"
                              variant="outlined"
                              margin="normal"
                              value={values.gender}
                              onChange={this.handleChange}
                            >
                              {GenderList.map((val, index) => (
                                <MenuItem key={val + index} value={val}>
                                  {val}
                                </MenuItem>
                              ))}
                            </TextField>
                            {errors.gender && touched.gender && (
                              <FormHelperText error>
                                {errors.gender}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Delivery Person Type
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="deliveryPersonType"
                              select
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              variant="outlined"
                              margin="normal"
                              value={values.deliveryPersonType}
                              onChange={this.handleChange}
                            >
                              {DeliveryPersonTypeOptions.map((val, index) => (
                                <MenuItem key={val + index} value={val}>
                                  {val}
                                </MenuItem>
                              ))}
                            </TextField>
                            {errors.deliveryPersonType &&
                              touched.deliveryPersonType && (
                                <FormHelperText error>
                                  {errors.deliveryPersonType}
                                </FormHelperText>
                              )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Address
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="address"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              variant="outlined"
                              placeholder="Enter Address"
                              type="text"
                              margin="normal"
                              multiline
                              rows={4}
                              value={values.address}
                              onChange={this.handleChange}
                            />
                            {errors.address && touched.address && (
                              <FormHelperText error>
                                {errors.address}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              experience
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="experience"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              variant="outlined"
                              placeholder="Enter Experience"
                              type="text"
                              margin="normal"
                              value={values.experience}
                              onChange={this.handleChange}
                            />
                            {errors.address && touched.address && (
                              <FormHelperText error>
                                {errors.address}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Working Time
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={5}>
                            <FormLabel>Start Time</FormLabel>
                            <TextField
                              name="workingStartTime"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              variant="outlined"
                              placeholder="Enter Working Time"
                              type="text"
                              margin="normal"
                              value={values.workingStartTime}
                              onChange={this.handleChange}
                            />
                            {errors.address && touched.address && (
                              <FormHelperText error>
                                {errors.address}
                              </FormHelperText>
                            )}
                          </GridItem>
                          <GridItem xs={12} sm={5}>
                            <FormLabel>End Time</FormLabel>
                            <TextField
                              name="WorkingEndTime"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              variant="outlined"
                              placeholder="Enter Working Time"
                              type="text"
                              margin="normal"
                              value={values.WorkingEndTime}
                              onChange={this.handleChange}
                            />
                            {errors.address && touched.address && (
                              <FormHelperText error>
                                {errors.address}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Working Days
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <FormControl
                              required
                              error={error}
                              component="fieldset"
                              className={classes.formControl}
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={monday}
                                      onChange={this.handleChecked}
                                      name="monday"
                                      color="default"
                                    />
                                  }
                                  label="Monday"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={tuesday}
                                      onChange={this.handleChecked}
                                      name="tuesday"
                                      color="default"
                                    />
                                  }
                                  label="Tuesday"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={wednesday}
                                      onChange={this.handleChecked}
                                      name="wednesday"
                                      color="default"
                                    />
                                  }
                                  label="Wednesday"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={thursday}
                                      onChange={this.handleChecked}
                                      name="thursday"
                                      color="default"
                                    />
                                  }
                                  label="Thursday"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={friday}
                                      onChange={this.handleChecked}
                                      name="friday"
                                      color="default"
                                    />
                                  }
                                  label="Friday"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={saturday}
                                      onChange={this.handleChecked}
                                      name="saturday"
                                      color="default"
                                    />
                                  }
                                  label="Saturday"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={sunday}
                                      onChange={this.handleChecked}
                                      name="sunday"
                                      color="default"
                                    />
                                  }
                                  label="Sunday"
                                />
                              </FormGroup>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          className={classes.deliveryPartnerDivSpacing}
                        >
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.deliveryPartnerTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Total Completed Services
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="totalOrders"
                              fullWidth
                              className={classes.deliveryPartnerFieldDivSpacing}
                              placeholder="Enter total completed orders"
                              variant="outlined"
                              margin="normal"
                              value={values.totalOrders}
                              onChange={this.handleChange}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12}>
                            <div style={{ float: "right" }}>
                              <Button color="info" onClick={handleSubmit}>
                                Save
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </Formik>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <Calendar
                    localizer={localizer}
                    events={myServiceList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600 }}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Suspense>
      </>
    );
  }
}

ManageDeliveryPartner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageDeliveryPartner);
