import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const userManagementStyle = {
  ...regularFormsStyle,
  ...extendedTablesStyle,
  manageUserCardTitle: {
    color: "#0000007d",
    paddingTop: '35px'
  },
  logoImage: {
    height: '50px'
  },
  customSwitch: {
    marginTop: 25
  },
  switchBase: {
    color: "#0cb4c9 !important"
  },
  floatRight: {
    float: 'right'
  },
  removeDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  doneLabel: {
    color: "#4caf50",
    fontWeight: 'bold'
  },
  linkColor: {
    color: 'inherit',
    "&:hover": {
      color: 'inherit',      
    }
  },

};

export default userManagementStyle;
