/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import AddIcon from "@material-ui/icons/Add";
import { TextField, FormHelperText } from "@material-ui/core";
import Notes from "@material-ui/icons/Notes";
import DeleteIcon from "@material-ui/icons/Delete";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardText = lazy(() => import("components/Card/CardText.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import styles from "assets/jss/material-dashboard-pro-react/views/userManagementStyle";

class ManageServiceCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: "",
      categoryLogo: "",
      subCategory: [""],
      fromEdit: (props.location.state && props.location.state.edit) || false,
    };
  }

  componentDidMount() {
    const { fromEdit } = this.state;
    fromEdit &&
      this.setState({
        categoryName: "Cleaning",
      });
  }

  onSubmit = () => {
    this.props.history.push("service-categories-list", { showToaster: true });
  };

  addSubcategory = () => {
    const { subCategory } = this.state;
    let subcategory = "";
    subCategory.push(subcategory);
    this.setState({ subCategory });
  };

  removeSubcategory = () => {
    const { subCategory } = this.state;
    let subcategory = "";
    subCategory.pop(subcategory);
    this.setState({ subCategory });
  };

  submitFormData = () => {
    console.log(this.state);
  };

  render() {
    const { classes } = this.props;
    const { categoryName, categoryLogo, subCategory } = this.state;

    return (
      <>
        <Suspense fallback={<> </>}>
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              categoryName: Yup.string().required("Category Name is required"),
              categoryLogo: Yup.string().required("Category Logo is required"),
              subCategory: Yup.string().required("Sub Category is required"),
            })}
            initialValues={{
              categoryLogo,
              categoryName,
              subCategory,
            }}
            onSubmit={(values) => {
              this.submitFormData(values);
              this.onSubmit();
            }}
          >
            {({ errors, values, touched, handleSubmit, handleChange }) => (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <div className={classes.divFlex}>
                          <Notes />
                          <h4 className={classes.cardTitle}>
                            Manage Service Categories
                          </h4>
                        </div>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Category Name
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="categoryName"
                              fullWidth
                              variant="outlined"
                              margin="normal"
                              value={values.categoryName}
                              onChange={handleChange}
                            />
                            {errors.categoryName && touched.categoryName && (
                              <FormHelperText error>
                                {errors.categoryName}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Logo
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="categoryLogo"
                              fullWidth
                              type="file"
                              variant="outlined"
                              margin="normal"
                              value={values.categoryLogo}
                              onChange={handleChange}
                            />
                            {errors.categoryLogo && touched.categoryLogo && (
                              <FormHelperText error>
                                {errors.categoryLogo}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        {subCategory.map((category, index) => (
                          <GridContainer key={category + index}>
                            <GridItem xs={12} sm={2}>
                              <FormLabel
                                className={
                                  classes.labelHorizontal +
                                  " " +
                                  classes.manageUserCardTitle
                                }
                              >
                                Subcategory
                              </FormLabel>
                            </GridItem>
                            <GridItem xs={12} sm={8}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                value={category}
                                onChange={(e) => {
                                  subCategory[index] = e.target.value;
                                  this.setState({
                                    subCategory,
                                  });
                                }}
                              />
                              {errors.subCategory && touched.subCategory && (
                                <FormHelperText error>
                                  {errors.subCategory}
                                </FormHelperText>
                              )}
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={2}
                              className={classes.removeDiv}
                            >
                              <Button
                                color="danger"
                                size="sm"
                                onClick={this.removeSubcategory}
                              >
                                <DeleteIcon />
                              </Button>
                            </GridItem>
                          </GridContainer>
                        ))}
                        <GridContainer justify="flex-end">
                          <GridItem xs={12} sm={2}>
                            <Button
                              // color="gray"
                              size="sm"
                              onClick={this.addSubcategory}
                            >
                              <AddIcon />
                            </Button>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12}>
                            <div style={{ float: "right" }}>
                              <Button color="info" onClick={handleSubmit}>
                                Save
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </Formik>
        </Suspense>
      </>
    );
  }
}

ManageServiceCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageServiceCategories);
