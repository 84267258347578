import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const OrderStyle = (theme) => ({
  ...regularFormsStyle,
  ...extendedTablesStyle,
  manageUserCardTitle: {
    color: "#0000007d",
  },
  deliveryPartnerDivSpacing: {
    marginBottom: "20px",
  },
  deliveryPartnerFieldDivSpacing: {
    margin: "0",
  },
  deliveryPartnerTitleDivSpacing: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& label": {
      padding: "0",
      fontWeight: "500",
    },
  },
  titleSpacing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleIcon: {
    marginRight: "5px",
  },
  cardIconTitle: {
    color: "#3C4858",
    marginTop: "15px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "0px",
    textDecoration: "none",
  },
  DeliveryPartnerList: {
    "& th": {
      fontWeight: "500",
    },
  },
  ListHeaderDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingLeft: "72px",
    alignItems: "center",
    "& h4": {
      marginTop: "0",
    },
  },
  ListHeaderSection: {
    display: "flex",
    position: "relative",
  },
  ListHeaderIconSection: {
    position: "absolute",
  },
  ListHeaderButton: {
    marginTop: "10px",
    padding: "10px 30px",
    fontWeight: "500",
    fontSize: "14px",
  },
  starRatingBlank: {
    "&::before": {
      content: "\f006",
    },
  },
  feedbackDescription: {
    fontSize: "16px",
    marginBottom: "15px !important",
  },
  feedbackSection: {
    marginTop: 20,
    marginBottom: 40,
  },
  feedbackSubService: {
    fontSize: 15,
  },
  feedbackService: {
      fontWeight: 400
  },
  floatRight: {
    float: "right"
  }
});

export default OrderStyle;
