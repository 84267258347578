import { infoColor } from "assets/jss/material-dashboard-pro-react";
import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  firstName: {
    width: "100%",
    "& label": {
      color: "#AAAAAA !important",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "1.42857",
    },
    "& input": {
      fontSize: "14px",
      color: "#000",
      fontWeight: "400",
    },
  },
  nameField: {
    position: "relative",
    marginBottom: "10px",
  },
  faceIcon: {
    position: "absolute",
    right: "0",
    bottom: "4px",
    color: grayColor[6],
  },
  loginButton: {
    width: "100%",
    backgroundColor: infoColor[0],
    fontWeight: "500",
    fontSize: "14px",
    borderRadius: "40px",
    "&:hover": {
      backgroundColor: infoColor[0],
    },
  },
  footerSpacing: {
    paddingTop: "0",
    display: "flex",
    flexDirection: "column",
  },
  passwordLinks: {
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  rememberMe: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "400",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: infoColor[0],
    },
  },
  loginForm: {
    position: "relative",
  },
  userImg: {
    height: "80px",
    width: "80px",
    border: `2px solid ${infoColor[0]}`,
    borderRadius: "50%",
    backgroundColor: infoColor[0],
    padding: "10px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  userImgDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "-42px",
  },
  // logInTitle: {

  // }
  logInCardBody: {
    paddingTop: "45px",
  },
  logInTitle: {
    fontSize: "26px",
    fontWeight: "500",
    color: infoColor[0],
    display: "flex",
    justifyContent: "center",
    paddingTop: "8px",
  },
  socialIconDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  socialIcon: {
    height: "25px",
    width: "25px",
    marginRight: "8px",
    display: "flex",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  socialIcon1: {
    display: "flex",
    alignItems: "center",
    borderRadius: "30px",
    border: "2px solid #ec407a",
    padding: "4px 8px",
    marginRight: "5px",
  },
  rememberPassword: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  forgotPassword: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  loginContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  loginBackground: {
    // background: "url(../wallpaper.jpg)",
    background:
      "url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/652/confectionary.png)",
  },
});

export default loginPageStyle;
