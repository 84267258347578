/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField, FormHelperText, MenuItem } from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import { Formik } from "formik";
import * as Yup from "yup";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardText = lazy(() => import("components/Card/CardText.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import styles from "./ManageServicesStyle.js";

class ManageServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEdit: (props.location.state && props.location.state.edit) || false,
      serviceName: "",
      serviceCost: "",
      included: "",
      serviceDesc: "",
      faq: "",
      hire: "",
      serviceCategory: "Cleaning",
      serviceCategoryOption: [
        "Cleaning",
        "Electricity",
        "Painting",
        "Remodeling",
      ],
    };
  }

  componentDidMount() {
    const { fromEdit } = this.state;
    fromEdit &&
      this.setState({
        serviceName: "Wall Painting",
        serviceCost: "$2000",
        serviceCategory: "Painting",
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleServiceDescriptionChange = (value) => {
    this.setState({
      serviceDesc: value,
    });
  };

  handleIncludeChange = (value) => {
    this.setState({
      included: value,
    });
  };

  handleFaqChange = (value) => {
    this.setState({
      faq: value,
    });
  };

  handleHireChange = (value) => {
    this.setState({
      hire: value,
    });
  };

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  onSubmit = () => {
    this.props.history.push("services-list", { showToaster: true });
  };

  render() {
    const { classes } = this.props;
    const {
      serviceName,
      serviceCost,
      included,
      serviceDesc,
      faq,
      hire,
      serviceCategory,
      serviceCategoryOption,
    } = this.state;

    return (
      <>
        <Suspense fallback={<div />}>
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              serviceName: Yup.string().required("Service Name is required"),
              serviceCost: Yup.string().required("Service Cost is required"),
              serviceCategory: Yup.string().required(
                "Service Category is required"
              ),
              included: Yup.string().required("Included is required"),
              serviceDesc: Yup.string().required(
                "Service Description is required"
              ),
              faq: Yup.string().required("FAQ is required"),
              hire: Yup.string().required("Restrict From is required"),
            })}
            initialValues={{
              serviceName,
              serviceCost,
              serviceCategory,
              included,
              serviceDesc,
              faq,
              hire,
            }}
            onSubmit={(values) => {
              this.onSubmit(values);
            }}
          >
            {({ errors, values, touched, handleSubmit, handleChange }) => (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <div className={classes.titleSpacing}>
                          <Person className={classes.titleIcon} />
                          <h4 className={classes.cardTitle}>Manage Services</h4>
                        </div>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <GridContainer className={classes.serviceDivSpacing}>
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.serviceTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Service Name
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="serviceName"
                              className={classes.serviceFieldDivSpacing}
                              fullWidth
                              placeholder="Enter Service Name"
                              variant="outlined"
                              margin="normal"
                              value={values.serviceName}
                              onChange={this.handleChange}
                            />
                            {errors.serviceName && touched.serviceName && (
                              <FormHelperText error>
                                {errors.serviceName}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer className={classes.serviceDivSpacing}>
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.serviceTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Service Cost
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="serviceCost"
                              className={classes.serviceFieldDivSpacing}
                              fullWidth
                              placeholder="Service Cost"
                              variant="outlined"
                              margin="normal"
                              value={values.serviceCost}
                              onChange={this.handleChange}
                            />
                            {errors.serviceCost && touched.serviceCost && (
                              <FormHelperText error>
                                {errors.serviceCost}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer className={classes.serviceDivSpacing}>
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.serviceTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Service Category
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="serviceCategory"
                              select
                              fullWidth
                              placeholder="Select Service Category"
                              variant="outlined"
                              margin="normal"
                              value={values.serviceCategory}
                              onChange={this.handleChange}
                            >
                              {serviceCategoryOption.map((val, index) => (
                                <MenuItem key={val + index} value={val}>
                                  {val}
                                </MenuItem>
                              ))}
                            </TextField>
                            {errors.serviceCost && touched.serviceCost && (
                              <FormHelperText error>
                                {errors.serviceCost}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer className={classes.serviceDivSpacing}>
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.serviceTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Service Description
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <ReactQuill
                              theme="snow"
                              modules={this.modules}
                              formats={this.formats}
                              value={serviceDesc}
                              onChange={this.handleServiceDescriptionChange}
                            ></ReactQuill>
                            {errors.serviceDesc && touched.serviceDesc && (
                              <FormHelperText error>
                                {errors.serviceDesc}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer className={classes.serviceDivSpacing}>
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.serviceTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              What's included in Service
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <ReactQuill
                              theme="snow"
                              modules={this.modules}
                              formats={this.formats}
                              value={included}
                              onChange={this.handleIncludeChange}
                            ></ReactQuill>
                            {errors.included && touched.included && (
                              <FormHelperText error>
                                {errors.included}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer className={classes.serviceDivSpacing}>
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.serviceTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              FAQ
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <ReactQuill
                              theme="snow"
                              modules={this.modules}
                              formats={this.formats}
                              value={faq}
                              onChange={this.handleFaqChange}
                            ></ReactQuill>
                            {errors.faq && touched.faq && (
                              <FormHelperText error>
                                {errors.faq}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer className={classes.serviceDivSpacing}>
                          <GridItem
                            xs={12}
                            sm={2}
                            className={classes.serviceTitleDivSpacing}
                          >
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Why hire us
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <ReactQuill
                              theme="snow"
                              modules={this.modules}
                              formats={this.formats}
                              value={hire}
                              onChange={this.handleHireChange}
                            ></ReactQuill>
                            {errors.hire && touched.hire && (
                              <FormHelperText error>
                                {errors.hire}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12}>
                            <div style={{ float: "right" }}>
                              <Button color="info" onClick={handleSubmit}>
                                Save
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </Formik>
        </Suspense>
      </>
    );
  }
}

ManageServices.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageServices);
