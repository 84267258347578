/*eslint-disable*/
import React, { Component, Suspense, lazy } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { withStyles } from "@material-ui/core/styles";
import Person from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Tooltip, TextField, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const CardIcon = lazy(() => import("components/Card/CardIcon.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const DeleteDialog = lazy(() =>
  import("components/DeleteDialog/DeleteDialog.js")
);

import { DeliveryPartnerListData } from "constant/Data";

import styles from "./DeliveryPartnerStyle.js";

class DeliveryPartnerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DeliveryPartnerData: DeliveryPartnerListData,
      openSnackBar:
        (props.location.state && props.location.state.showToaster) || false,
      snackBarVariant: "success",
      response: "Data Updated Successfully !",
      openDialog: false,
      deleteIndex: "",
      isSnackbarOpen: false,
    };
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== "undefined") {
      if (typeof content === "object" && content !== null && content.key) {
        return String(content.key)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      } else {
        return String(content)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      }
    }

    return true;
  };

  goToManage = (edit) => {
    this.props.history.push("manage-delivery-partner", { edit });
  };

  handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      openSnackBar: false,
    });
  };

  openDeleteDialog = (index) => {
    this.setState({
      openDialog: true,
      deleteIndex: index,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  deleteItem = (index) => {
    const list = this.state.DeliveryPartnerData;
    list.splice(index, 1);
    this.setState({
      DeliveryPartnerData: list,
      openDialog: false,
      openSnackBar: true,
      response: "Data Deleted Successfully !",
    });
  };

  componentWillUnmount() {
    this.setState({
      openSnackBar: false,
    });
  }

  render() {
    const columns = [
      {
        id: "id",
        Header: "No",
        width: 130,
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14,
        },
        Cell: (row) => <div>{row.index + 1}</div>,
      },
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: (row) => <div>{row.value}</div>,
        Filter: ({ filter, onChange }) => (
          <TextField
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            variant="outlined"
            placeholder="Search By Name"
            fullWidth
          />
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14,
        },
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: (row) => <div>{row.value}</div>,
        Filter: ({ filter, onChange }) => (
          <TextField
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            variant="outlined"
            placeholder="Search By Email"
            fullWidth
          />
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14,
        },
      },
      {
        id: "phoneNumber",
        Header: "Phone Number",
        accessor: "phoneNumber",
        filterable: true,
        Cell: (row) => <div>{row.value}</div>,
        Filter: ({ filter, onChange }) => (
          <TextField
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            variant="outlined"
            placeholder="Search By Phone Number"
            fullWidth
          />
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14,
        },
      },
      {
        id: "gender",
        Header: "Gender",
        accessor: "gender",
        filterable: true,
        Cell: (row) => <div>{row.value}</div>,
        Filter: ({ filter, onChange }) => (
          <TextField
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            variant="outlined"
            placeholder="Search By Gender"
            fullWidth
          />
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14,
        },
      },
      {
        id: "personType",
        Header: "Delivery Person Type",
        accessor: "personType",
        filterable: true,
        Cell: (row) => <div>{row.value}</div>,
        Filter: ({ filter, onChange }) => (
          <TextField
            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            variant="outlined"
            placeholder="Search By Person Type"
            fullWidth
          />
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14,
        },
      },
      {
        Header: "Action",
        filterable: false,
        sortable: false,
        width: 100,
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14,
        },
        Cell: (row) => (
          <div
            style={{
              whiteSpace: "unset",
              cursor: "pointer",
              textDecoration: "underline",
              color: "#3f51b5",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Tooltip title="Edit">
              <EditIcon
                className="iconEdit"
                onClick={(edit) => this.goToManage((edit = true))}
              />
            </Tooltip>
            <Tooltip title="Remove">
              <DeleteIcon
                className="iconRemove"
                onClick={() => this.openDeleteDialog(row.index)}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
    const { classes } = this.props;
    const {
      openSnackBar,
      snackBarVariant,
      response,
      openDialog,
      DeliveryPartnerData,
      deleteIndex,
    } = this.state;
    return (
      <Suspense fallback={<div />}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={openSnackBar}
          autoHideDuration={2000}
          onClose={this.handleCloseSnackbar}
        >
          <Alert onClose={this.handleCloseSnackbar} severity={snackBarVariant}>
            {response}
          </Alert>
        </Snackbar>
        <DeleteDialog
          open={openDialog}
          handleClose={this.handleCloseDialog}
          deleteItem={this.deleteItem}
          index={deleteIndex}
        />

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <Person />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Delivery Partner List</h4>
                <Button
                  color="info"
                  className={classes.floatRight}
                  onClick={(edit) => this.goToManage((edit = false))}
                >
                  Add
                </Button>
              </CardHeader>
              <CardBody>
                <ReactTable
                  className={"-highlight"}
                  noDataText="No user matching the provided criteria"
                  columns={columns}
                  data={DeliveryPartnerData}
                  defaultPageSize={15}
                  defaultFilterMethod={this.filterCaseInsensitive}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Suspense>
    );
  }
}
export default withStyles(styles)(DeliveryPartnerList);
