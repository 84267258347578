export const UserListData = [
  {
    name: "Andrew Mike",
    email: "andrewMike@gmail.com",
    phoneNumber: "+1-541-754-3010",
    userType: "Customer",
  },
  {
    name: "John Doe",
    email: "johnDoe@gmail.com",
    phoneNumber: "+1-541-758-3215",
    userType: "Customer",
  },
  {
    name: "Alex Mike",
    email: "alexMike@yahoo.com",
    phoneNumber: "+1-541-785-5114",
    userType: "Vendor",
  },
  {
    name: "Mike Monday",
    email: "mikeMonday@yahoo.com",
    phoneNumber: "+1-541-787-4110",
    userType: "Experts",
  },
  {
    name: "Paul Dickens",
    email: "paulDickens@yahoo.com",
    phoneNumber: "+1-541-787-7110",
    userType: "Customer",
  },
];

export const ServiceCategoryListData = [
  {
    logo: require("assets/img/cleaning.svg"),
    serviceName: "Cleaning",
  },
  {
    logo: require("assets/img/electricity.svg"),
    serviceName: "Electricity",
  },
  {
    logo: require("assets/img/painting.svg"),
    serviceName: "Painting",
  },
  {
    logo: require("assets/img/remodeling.svg"),
    serviceName: "Remodeling",
  },
];

export const ServiceListData = [
  {
    name: "Wall Painting",
    category: "Painting",
    cost: "$2000",
  },
  {
    name: "Home Remodeling Service",
    category: "Remodeling",
    cost: "$3000",
  },
  {
    name: "Home Painting",
    category: "Painting",
    cost: "$4000",
  },
  {
    name: "AC Service",
    category: "Repairing",
    cost: "$5000",
  },
  {
    name: "House Cleaning",
    category: "Cleaning",
    cost: "$6000",
  },
  {
    name: "Plumber Service",
    category: "Repairing",
    cost: "$1000",
  },
];

export const AffiliateListData = [
  {
    name: "Andrew Mike",
    email: "andrewMike@gmail.com",
    phoneNumber: "+1-541-754-3010",
    userType: "Customer",
  },
  {
    name: "John Doe",
    email: "johnDoe@gmail.com",
    phoneNumber: "+1-541-758-3215",
    userType: "Customer",
  },
  {
    name: "Alex Mike",
    email: "alexMike@yahoo.com",
    phoneNumber: "+1-541-785-5114",
    userType: "Vendor",
  },
  {
    name: "Mike Monday",
    email: "mikeMonday@yahoo.com",
    phoneNumber: "+1-541-787-4110",
    userType: "Experts",
  },
  {
    name: "Paul Dickens",
    email: "paulDickens@yahoo.com",
    phoneNumber: "+1-541-787-7110",
    userType: "Customer",
  },
];

export const DeliveryPartnerListData = [
  {
    name: "Andrew Mike",
    email: "andrewMike@gmail.com",
    phoneNumber: "+1-675-754-3010",
    gender: "Male",
    personType: "House Cleaner",
  },
  {
    name: "John Doe",
    email: "johnDoe@gmail.com",
    phoneNumber: "+1-978-758-3215",
    gender: "Male",
    personType: "Plumber",
  },
  {
    name: "Alex Mike",
    email: "alexMike@yahoo.com",
    phoneNumber: "+1-364-785-5114",
    gender: "Male",
    personType: "Electrician",
  },
  {
    name: "Mike Monday",
    email: "mikeMonday@yahoo.com",
    phoneNumber: "+1-978-787-4110",
    gender: "Female",
    personType: "Painter",
  },
  {
    name: "Paul Dickens",
    email: "paulDickens@yahoo.com",
    phoneNumber: "+1-587-728-7110",
    gender: "Male",
    personType: "House Remodel Architecture",
  },
];

export const ServiceCallListData = [
  {
    callFrom: "Andrew Mike",
    phoneNumber: "+1-541-754-3010",
    date: "10-02-2020",
    serviceType: "Cleaning",
    status: "Done",
  },
  {
    callFrom: "John Doe",
    phoneNumber: "+1-541-758-3215",
    date: "15-02-2020",
    serviceType: "Electricity",
    status: "Done",
  },
  {
    callFrom: "Alex Mike",
    phoneNumber: "+1-541-785-5114",
    date: "08-03-2020",
    serviceType: "Painting",
    status: "Done",
  },
  {
    callFrom: "Mike Monday",
    phoneNumber: "+1-541-787-4110",
    date: "05-05-2020",
    serviceType: "Remodeling",
    status: "Done",
  },
  {
    callFrom: "Paul Dickens",
    phoneNumber: "+1-541-728-7110",
    date: "25-05-2020",
    serviceType: "Painting",
    status: "Done",
  },
];

export const ServiceProviderListData = [
  {
    vendorName: "Andrew Mike",
    email: "andrewMike@gmail.com",
    phoneNumber: "+1-541-754-3010",
    isSuspended: "No",
    isBlocked: "No",
    isInactivated: "No",
  },
  {
    vendorName: "John Doe",
    email: "johnDoe@gmail.com",
    phoneNumber: "+1-541-758-3215",
    isSuspended: "No",
    isBlocked: "No",
    isInactivated: "No",
  },
  {
    vendorName: "Alex Mike",
    email: "alexMike@gmail.com",
    phoneNumber: "+1-541-785-5114",
    isSuspended: "No",
    isBlocked: "No",
    isInactivated: "No",
  },
];

export const VendorPaymentListData = [
  {
    vendorName: "Andrew Mike",
    email: "andrewMike@gmail.com",
    phoneNumber: "+1-541-754-3010",
  },
  {
    vendorName: "John Doe",
    email: "johnDoe@gmail.com",
    phoneNumber: "+1-541-758-3215",
  },
  {
    vendorName: "Alex Mike",
    email: "alexMike@gmail.com",
    phoneNumber: "+1-541-785-5114",
  },
];

export const CustomerServiceListData = [
  {
    no: "101",
    type: "Query",
    description: "Query",
  },
  {
    no: "102",
    type: "Concern",
    description: "Concern",
  },
  {
    no: "103",
    type: "Query",
    description: "Query",
  },
  {
    no: "104",
    type: "Complain",
    description: "Complain",
  },
  {
    no: "105",
    type: "Concern",
    description: "Concern",
  },
];

export const CustomerListData = [
  {
    name: "Andrew Mike",
    email: "andrewMike@gmail.com",
    phoneNumber: "+1-541-754-3010",
    gender: "Male",
    isSuspended: "No",
    isTerminate: "No",
    isActivate: "No",
  },
  {
    name: "John Doe",
    email: "johnDoe@gmail.com",
    phoneNumber: "+1-541-758-3215",
    gender: "Male",
    isSuspended: "No",
    isTerminate: "No",
    isActivate: "No",
  },
  {
    name: "Alex Mike",
    email: "alexMike@yahoo.com",
    phoneNumber: "+1-541-785-5114",
    gender: "Male",
    isSuspended: "No",
    isTerminate: "No",
    isActivate: "No",
  },
  {
    name: "Mike Monday",
    email: "mikeMonday@yahoo.com",
    phoneNumber: "+1-541-787-4110",
    gender: "Female",
    isSuspended: "No",
    isTerminate: "No",
    isActivate: "No",
  },
  {
    name: "Paul Dickens",
    email: "paulDickens@yahoo.com",
    phoneNumber: "+1-541-787-7110",
    gender: "Male",
    isSuspended: "No",
    isTerminate: "No",
    isActivate: "No",
  },
];

export const OrderListData = [
  {
    name: "Painting",
    servicePerson: "John Doe",
    serviceDate: "23-09-2019",
    serviceTime: "10:10 AM",
    serviceRatings: "4",
  },
  {
    name: "House Cleaning",
    servicePerson: "Andrew Mike",
    serviceDate: "23-09-2017",
    serviceTime: "08:10 PM",
    serviceRatings: "3.5",
  },
  {
    name: "Plumbing",
    servicePerson: "Mike Monday",
    serviceDate: "23-09-2018",
    serviceTime: "11:10 AM",
    serviceRatings: "3",
  },
  {
    name: "Beauty Services",
    servicePerson: "Paul Dickens",
    serviceDate: "23-09-2017",
    serviceTime: "10:30 AM",
    serviceRatings: "4",
  },
  {
    name: "House Remodelling",
    servicePerson: "John Doe",
    serviceDate: "23-09-2018",
    serviceTime: "10:10 AM",
    serviceRatings: "4.5",
  },
  {
    name: "	AC Fitting",
    servicePerson: "Mike Monday",
    serviceDate: "23-09-2018",
    serviceTime: "12:10 AM",
    serviceRatings: "4",
  },
];

export const PromotionListData = [
  {
    promotionCode: "SPRU50",
    activation: "Active",
    description: "You will get 50% discount offer on special services",
  },
  {
    promotionCode: "SPRU10",
    activation: "Active",
    description: "You will get 10% discount offer on special services",
  },
  {
    promotionCode: "SPRU15",
    activation: "Active",
    description: "You will get 15% discount offer on special services",
  },
];

export const GenderList = ["Male", "Female"];

export const UserTypeOption = ["Admin", "Experts", "customer"];

export const  RestrictionOptions = [
  "Cleaning",
  "Staging/Design",
  "Photography",
  "Painting",
  "Gardening",
  "Landscaping",
  "plumbing",
  "Electric",
  "HVAC",
  "Foundation",
  "Roofing",
];

export const DeliveryPersonTypeOptions = [
  "House Cleaner",
  "Plumber",
  "beautician",
  "Painter",
  "AC fitter",
  "House Remodel Architecture",
  "plumbing",
  "Electrician",
];