import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const ManageServicesStyle = (theme) => ({
  ...regularFormsStyle,
  ...extendedTablesStyle,
  manageUserCardTitle: {
    color: "#0000007d",
  },
  serviceDivSpacing: {
    marginBottom: "20px",
  },
  serviceFieldDivSpacing: {
    margin: "0",
  },
  serviceTitleDivSpacing: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& label": {
      padding: "0",
      fontWeight: "500",
    },
  },
  titleSpacing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleIcon: {
    marginRight: "5px",
  },
  cardIconTitle: {
    color: "#3C4858",
    marginTop: "15px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "0px",
    textDecoration: "none",
  },
  servicesList: {
    "& th": {
      fontWeight: "500",
    },
  },
  floatRight: {
    float: "right",
  },
});

export default ManageServicesStyle;
