/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import OfferIcon from "@material-ui/icons/LocalOffer";
import FormLabel from "@material-ui/core/FormLabel";
import {
  TextField,
  FormHelperText,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardText = lazy(() => import("components/Card/CardText.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import styles from "assets/jss/material-dashboard-pro-react/views/userManagementStyle";

class ManagePromotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEdit: (props.location.state && props.location.state.edit) || false,
      promotionCode: "",
      promotionDescription: "",
      activeOption: ["Active", "Inactive"],
      isActivated: false,
    };
  }

  componentDidMount() {
    const { fromEdit } = this.state;
    fromEdit &&
      this.setState({
        promotionCode: "SPRU50",
        promotionDescription:
          "You will get 50% discount offer on special services",
        isActivated: true,
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = () => {
    this.props.history.push("promotion-list", { showToaster: true });
  };

  handleChangeTerminate = () => {
    this.setState((prevState) => ({
      isActivated: !prevState.isActivated,
    }));
  };

  render() {
    const { classes } = this.props;
    const {
      promotionCode,
      promotionDescription,
      isActivated,
    } = this.state;

    return (
      <>
        <Suspense fallback={<div />}>
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              promotionCode: Yup.string().required(
                "Promotion Code is required"
              ),
              promotionDescription: Yup.string().required(
                "Promotion Description is required"
              ),
              isActivated: Yup.string().required(" Activation is required "),
            })}
            initialValues={{
              promotionCode,
              promotionDescription,
              isActivated,
            }}
            onSubmit={(values) => {
              this.onSubmit(values);
            }}
          >
            {({ errors, values, touched, handleSubmit }) => (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <div className={classes.divFlex}>
                          <OfferIcon />
                          <h4 className={classes.cardTitle}>
                            Manage Promotion
                          </h4>
                        </div>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Promotion Code
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="promotionCode"
                              fullWidth
                              placeholder="Enter Promotion Code"
                              variant="outlined"
                              margin="normal"
                              value={values.promotionCode}
                              onChange={this.handleChange}
                            />
                            {errors.promotionCode && touched.promotionCode && (
                              <FormHelperText error>
                                {" "}
                                {errors.promotionCode}{" "}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Promotion Description
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="promotionDescription"
                              fullWidth
                              placeholder="Enter Promotion Description"
                              variant="outlined"
                              multiline
                              rows={4}
                              rowsMax={7}
                              margin="normal"
                              value={promotionDescription}
                              onChange={this.handleChange}
                            />
                            {errors.promotionDescription &&
                              touched.promotionDescription && (
                                <FormHelperText error>
                                  {" "}
                                  {errors.promotionDescription}{" "}
                                </FormHelperText>
                              )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Promotion Activation
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <FormControlLabel
                              style={{ marginTop: 25 }}
                              control={
                                <Switch
                                  checked={isActivated}
                                  onChange={this.handleChangeTerminate}
                                  value="isActivated"
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12}>
                            <div style={{ float: "right" }}>
                              <Button color="info" onClick={handleSubmit}>
                                Save
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </Formik>
        </Suspense>
      </>
    );
  }
}

ManagePromotion.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManagePromotion);
