/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import StarRatings from "react-star-ratings";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField } from "@material-ui/core";
import BorderColorRoundedIcon from "@material-ui/icons/BorderColorRounded";
import FeedbackIcon from "@material-ui/icons/Feedback";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardText = lazy(() => import("components/Card/CardText.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));

import styles from "./OrderStyle.js";

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNo: 101,
      serviceCategory: "Painting",
      serviceName: "Wall Painting",
      servicePerson: "John Doe",
      serviceDate: "23/09/2019",
      serviceTime: "10:10 AM",
      feedbackTopic: "",
      feedbackDescription: "",
      feedbackDate: "",
      rating: 4,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = () => {
    console.log(this.state, "state");
  };

  changeRating = (newRating) => {
    this.setState({
      rating: newRating,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      orderNo,
      serviceCategory,
      serviceName,
      servicePerson,
      serviceDate,
      serviceTime,     
      rating,
    } = this.state;

    return (
      <>
        <Suspense fallback={<div />}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <div className={classes.titleSpacing}>
                      <BorderColorRoundedIcon className={classes.titleIcon} />
                      <h4 className={classes.cardTitle}>Order Details</h4>
                    </div>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer
                      className={classes.deliveryPartnerDivSpacing}
                    >
                      <GridItem
                        xs={12}
                        sm={2}
                        className={classes.deliveryPartnerTitleDivSpacing}
                      >
                        <FormLabel
                          className={
                            classes.labelHorizontal +
                            " " +
                            classes.manageUserCardTitle
                          }
                        >
                          Order No.
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <TextField
                          name="orderNo"
                          fullWidth
                          className={classes.deliveryPartnerFieldDivSpacing}
                          variant="outlined"
                          type="text"
                          margin="normal"
                          value={orderNo}
                          onChange={this.handleChange}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      className={classes.deliveryPartnerDivSpacing}
                    >
                      <GridItem
                        xs={12}
                        sm={2}
                        className={classes.deliveryPartnerTitleDivSpacing}
                      >
                        <FormLabel
                          className={
                            classes.labelHorizontal +
                            " " +
                            classes.manageUserCardTitle
                          }
                        >
                          Service Category
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <TextField
                          name="serviceCategory"
                          fullWidth
                          className={classes.deliveryPartnerFieldDivSpacing}
                          variant="outlined"
                          type="text"
                          margin="normal"
                          value={serviceCategory}
                          onChange={this.handleChange}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      className={classes.deliveryPartnerDivSpacing}
                    >
                      <GridItem
                        xs={12}
                        sm={2}
                        className={classes.deliveryPartnerTitleDivSpacing}
                      >
                        <FormLabel
                          className={
                            classes.labelHorizontal +
                            " " +
                            classes.manageUserCardTitle
                          }
                        >
                          Service Name
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <TextField
                          name="serviceName"
                          fullWidth
                          className={classes.deliveryPartnerFieldDivSpacing}
                          variant="outlined"
                          type="text"
                          margin="normal"
                          value={serviceName}
                          onChange={this.handleChange}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      className={classes.deliveryPartnerDivSpacing}
                    >
                      <GridItem
                        xs={12}
                        sm={2}
                        className={classes.deliveryPartnerTitleDivSpacing}
                      >
                        <FormLabel
                          className={
                            classes.labelHorizontal +
                            " " +
                            classes.manageUserCardTitle
                          }
                        >
                          Service Person
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <TextField
                          name="servicePerson"
                          fullWidth
                          className={classes.deliveryPartnerFieldDivSpacing}
                          variant="outlined"
                          type="text"
                          margin="normal"
                          value={servicePerson}
                          onChange={this.handleChange}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      className={classes.deliveryPartnerDivSpacing}
                    >
                      <GridItem
                        xs={12}
                        sm={2}
                        className={classes.deliveryPartnerTitleDivSpacing}
                      >
                        <FormLabel
                          className={
                            classes.labelHorizontal +
                            " " +
                            classes.manageUserCardTitle
                          }
                        >
                          Service Date
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <TextField
                          name="serviceDate"
                          fullWidth
                          className={classes.deliveryPartnerFieldDivSpacing}
                          variant="outlined"
                          type="text"
                          margin="normal"
                          value={serviceDate}
                          onChange={this.handleChange}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      className={classes.deliveryPartnerDivSpacing}
                    >
                      <GridItem
                        xs={12}
                        sm={2}
                        className={classes.deliveryPartnerTitleDivSpacing}
                      >
                        <FormLabel
                          className={
                            classes.labelHorizontal +
                            " " +
                            classes.manageUserCardTitle
                          }
                        >
                          Service Time
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <TextField
                          name="serviceTime"
                          fullWidth
                          className={classes.deliveryPartnerFieldDivSpacing}
                          variant="outlined"
                          type="text"
                          margin="normal"
                          value={serviceTime}
                          onChange={this.handleChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <div className={classes.titleSpacing}>
                      <FeedbackIcon className={classes.titleIcon} />
                      <h4 className={classes.cardTitle}>Feedback Details</h4>
                    </div>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer justify="center">
                      <GridItem sm={10} md={8}>
                        <div className={classes.feedbackSection}>
                          <h3 className={classes.feedbackService}>
                            {" "}
                            Painting{" "}
                            <span className={classes.feedbackSubService}>
                              (Wall Painting)
                            </span>
                          </h3>
                          <p className={classes.feedbackDescription}>
                            Your team took up the painting job professionally
                            and completed within taken time. Nice job.
                          </p>
                          <StarRatings
                            rating={rating}
                            changeRating={this.changeRating}
                            starDimension="25px"
                            starSpacing="5px"
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Suspense>
      </>
    );
  }
}

OrderDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderDetails);
