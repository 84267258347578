// import Buttons from "views/Components/Buttons.js";
// import Charts from "views/Charts/Charts.js";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Notes from "@material-ui/icons/Notes";
import GroupIcon from "@material-ui/icons/Group";
import offerIcon from "@material-ui/icons/LocalOffer";
import CallIcon from "@material-ui/icons/Call";

import Dashboard from "views/Dashboard/Dashboard.js";
import ManageUser from "views/UserManagement/ManageUser";
import UserList from "views/UserManagement/UserList";
import ServiceCategoriesList from "views/ServiceCategoriesManagement/CategoriesList";
import ManageServiceCategories from "views/ServiceCategoriesManagement/ManageCategories";
import AffiliateServiceList from "views/AffiliateServiceManagement/AffiliateServiceList";
import ManageAffiliateService from "views/AffiliateServiceManagement/ManageAffiliateService";
import ServiceProviderList from "views/ServiceProvider/ServiceProviderList";
import ManageServiceProvider from "views/ServiceProvider/ManageServiceProvider";
import ManageServices from "views/ServicesManagement/ManageServices";
import ServicesList from "views/ServicesManagement/ServicesList";
import ManageDeliveryPartners from "views/DeliveryPartnerManagement/ManageDeliveryPartners";
import DeliveryPartnerList from "views/DeliveryPartnerManagement/DeliveryPartnerList";
import ManageCustomer from "views/CustomerManagement/ManageCustomer";
import CustomerList from "views/CustomerManagement/CustomerList";
import OrderList from "views/OrderManagement/OrderList";
import CustomerService from "views/CustomerService/CustomerServiceList";
import ManageCustomerService from "views/CustomerService/ManageCustomerService";
import promotionList from "views/PromotionAndOffer/PromotionList";
import ManagePromotion from "views/PromotionAndOffer/ManagePromotion";
import VendorPaymentList from "views/ServiceProvider/VendorPaymentList";
import ManageVendorPayment from "views/ServiceProvider/ManageVendorPayment";
import OrderDetails from "views/OrderManagement/OrderDetails";
import ServiceCallList from "views/ServiceCallManagement/ServiceCallList";
// import ManageServiceCall from "views/ServiceCallManagement/ManageServiceCall";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "User Management",
    icon: Person,
    state: "usersCollapse",
    views: [
      {
        path: "/user-list",
        name: "User List",
        rtlName: "خرائط جوجل",
        mini: "UL",
        rtlMini: "زم",
        component: UserList,
        layout: "/admin",
      },
      {
        path: "/manage-user",
        name: "Manage User",
        rtlName: "خريطة كاملة الشاشة",
        mini: "MU",
        rtlMini: "ووم",
        component: ManageUser,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Service Categories",
    icon: Notes,
    state: "serviceCollapse",
    views: [
      {
        path: "/service-categories-list",
        name: "Categories List",
        rtlName: "خرائط جوجل",
        mini: "CL",
        rtlMini: "زم",
        component: ServiceCategoriesList,
        layout: "/admin",
      },
      {
        path: "/manage-service-categories",
        name: "Manage Categories",
        rtlName: "خريطة كاملة الشاشة",
        mini: "MC",
        rtlMini: "ووم",
        component: ManageServiceCategories,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Services Management",
    icon: Person,
    state: "servicesCollapse",
    views: [
      {
        path: "/services-list",
        name: "Services List",
        rtlName: "خرائط جوجل",
        mini: "SL",
        rtlMini: "زم",
        component: ServicesList,
        layout: "/admin",
      },
      {
        path: "/manage-services",
        name: "Manage Services",
        rtlName: "خريطة كاملة الشاشة",
        mini: "MS",
        rtlMini: "ووم",
        component: ManageServices,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Affiliate Services",
    icon: Notes,
    state: "affiliateServiceCollapse",
    views: [
      {
        path: "/affiliate-service-list",
        name: "Affiliate List",
        rtlName: "خرائط جوجل",
        mini: "AL",
        rtlMini: "زم",
        component: AffiliateServiceList,
        layout: "/admin",
      },
      {
        path: "/manage-affiliate-service",
        name: "Manage Affiliate Service",
        rtlName: "خريطة كاملة الشاشة",
        mini: "MAS",
        rtlMini: "ووم",
        component: ManageAffiliateService,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Delivery Management",
    icon: Person,
    state: "deliveryPartnerCollapse",
    views: [
      {
        path: "/delivery-partner-list",
        name: "Delivery Partner List",
        rtlName: "خرائط جوجل",
        mini: "DPL",
        rtlMini: "زم",
        component: DeliveryPartnerList,
        layout: "/admin",
      },
      {
        path: "/manage-delivery-partner",
        name: "Manage Delivery Partner",
        rtlName: "خريطة كاملة الشاشة",
        mini: "MDS",
        rtlMini: "ووم",
        component: ManageDeliveryPartners,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Order/Service Call Management",
    icon: CallIcon,
    state: "serviceCallCollapse",
    views: [
      {
        path: "/service-call-list",
        name: "Service Call List",
        rtlName: "خرائط جوجل",
        mini: "SCL",
        rtlMini: "زم",
        component: ServiceCallList,
        layout: "/admin",
      },
      // {
      //   path: "/manage-service-call",
      //   name: "Manage Service Call",
      //   rtlName: "خريطة كاملة الشاشة",
      //   mini: "MSC",
      //   rtlMini: "ووم",
      //   component: ManageServiceCall,
      //   layout: "/admin",
      // },
    ],
  },
  {
    collapse: true,
    name: "Service Providers",
    icon: GroupIcon,
    state: "serviceProviderCollapse",
    views: [
      {
        path: "/service-provider-list",
        name: "Service Provider List",
        rtlName: "خرائط جوجل",
        mini: "SPL",
        rtlMini: "زم",
        component: ServiceProviderList,
        layout: "/admin",
      },
      {
        path: "/manage-service-provider",
        name: "Manage Service Provider",
        rtlName: "خريطة كاملة الشاشة",
        mini: "MSP",
        rtlMini: "ووم",
        component: ManageServiceProvider,
        layout: "/admin",
      },
      {
        path: "/vendor-payment-list",
        name: "Vendor Payment List",
        rtlName: "خريطة كاملة الشاشة",
        mini: "VPL",
        rtlMini: "ووم",
        component: VendorPaymentList,
        layout: "/admin",
      },
      {
        path: "/manage-vendor-payment",
        name: "Manage Vendor Payment",
        rtlName: "خريطة كاملة الشاشة",
        mini: "MVP",
        rtlMini: "ووم",
        component: ManageVendorPayment,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Customer Service",
    icon: GroupIcon,
    state: "customerServiceCollapse",
    views: [
      {
        path: "/customer-service-list",
        name: "Customer Service List",
        rtlName: "خرائط جوجل",
        mini: "CSL",
        rtlMini: "زم",
        component: CustomerService,
        layout: "/admin",
      },
      {
        path: "/manage-customer-service",
        name: "Manage Customer Service",
        mini: "MCS",
        // rtlName: "خريطة كاملة الشاشة",
        // rtlMini: "ووم",
        component: ManageCustomerService,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Customer Management",
    icon: GroupIcon,
    state: "customerTicketCollapse",
    views: [
      {
        path: "/customer-list",
        name: "Customer List",
        rtlName: "خرائط جوجل",
        mini: "CL",
        rtlMini: "زم",
        component: CustomerList,
        layout: "/admin",
      },
      {      
        path: "/manage-customers",
        name: "Manage Customer",
        rtlName: "خرائط جوجل",
        mini: "CL",
        rtlMini: "زم",
        component: ManageCustomer,
        layout: "/admin",
      },
      {
        path: "/order-list",
        name: "Order List",
        rtlName: "خرائط جوجل",
        mini: "OL",
        rtlMini: "زم",
        component: OrderList,
        layout: "/admin",
      },
      {
        path: "/order-details",
        name: "Order Details",
        rtlName: "خريطة كاملة الشاشة",
        mini: "OD",
        rtlMini: "ووم",
        component: OrderDetails,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Promotion and Offers",
    icon: offerIcon,
    state: "promotionOffersCollapse",
    views: [
      {
        path: "/promotion-list",
        name: "Promotion List",
        rtlName: "خرائط جوجل",
        mini: "PL",
        rtlMini: "زم",
        component: promotionList,
        layout: "/admin",
      },
      {
        path: "/manage-promotion",
        name: "Manage Promotion",
        rtlName: "خريطة كاملة الشاشة",
        mini: "MP",
        rtlMini: "ووم",
        component: ManagePromotion,
        layout: "/admin",
      },
    ],
  },
];
export default dashRoutes;
