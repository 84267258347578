/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import { TextField, FormHelperText } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Person from "@material-ui/icons/Person";
import { Formik } from "formik";
import * as Yup from "yup";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardText = lazy(() => import("components/Card/CardText.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import styles from "assets/jss/material-dashboard-pro-react/views/userManagementStyle";

class ManageServiceProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEdit: (props.location.state && props.location.state.edit) || false,
      name: "",
      email: "",
      phoneNumber: "",
      isSuspended: false,
      isBlocked: false,
      isInActivated: false,
      document: "",
    };
  }

  componentDidMount() {
    const { fromEdit } = this.state;
    fromEdit &&
      this.setState({
        name: "Andrew Mike",
        email: "andrewMike@gmail.com",
        phoneNumber: "+1-541-754-3010",
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeInactivate = () => {
    this.setState((prevState) => ({
      isInActivated: !prevState.isInActivated,
    }));
  };

  handleChangeBlock = () => {
    this.setState((prevState) => ({
      isBlocked: !prevState.isBlocked,
    }));
  };

  handleChangeSuspense = () => {
    this.setState((prevState) => ({
      isSuspended: !prevState.isSuspended,
    }));
  };

  onSubmit = () => {
    this.props.history.push("service-provider-list", { showToaster: true });
  };

  render() {
    const { classes } = this.props;
    const {
      name,
      email,
      phoneNumber,
      isSuspended,
      isBlocked,
      isInActivated,
      document,
    } = this.state;
    console.log();
    return (
      <>
        <Suspense fallback={<div />}>
          {" "}
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              email: Yup.string()
                .required("Email is required")
                .email("Email is invalid"),
              phoneNumber: Yup.string().required("Phone Number is required"),
              // document: Yup.string().required("Document is required"),
            })}
            initialValues={{
              name,
              email,
              phoneNumber,
              // document,
            }}
            onSubmit={(values) => {
              this.onSubmit(values);
            }}
          >
            {({ errors, values, touched, handleSubmit }) => (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <div className={classes.divFlex}>
                          <Person />
                          <h4 className={classes.cardTitle}>
                            Manage Service Provider
                          </h4>
                        </div>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Name
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="name"
                              fullWidth
                              placeholder="Enter Name"
                              variant="outlined"
                              margin="normal"
                              value={values.name}
                              onChange={this.handleChange}
                            />
                            {errors.name && touched.name && (
                              <FormHelperText error>
                                {errors.name}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Email
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="email"
                              fullWidth
                              placeholder="Enter Email"
                              type="email"
                              variant="outlined"
                              margin="normal"
                              value={values.email}
                              onChange={this.handleChange}
                            />
                            {errors.email && touched.email && (
                              <FormHelperText error>
                                {errors.email}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Phone Number
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="phoneNumber"
                              fullWidth
                              variant="outlined"
                              placeholder="Enter Phone Number"
                              margin="normal"
                              value={values.phoneNumber}
                              onChange={this.handleChange}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <FormHelperText error>
                                {errors.phoneNumber}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Upload Document
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <TextField
                              name="document"
                              fullWidth
                              variant="outlined"
                              placeholder="Please Upload Document"
                              type="file"
                              margin="normal"
                              value={document}
                              onChange={this.handleChange}
                            />
                            {/* {errors.document && touched.document && (
                            <FormHelperText error>
                              {errors.document}
                            </FormHelperText>
                          )} */}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Suspended
                            </FormLabel>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={10}
                            className={classes.customSwitch}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isSuspended}
                                  onChange={this.handleChangeSuspense}
                                  value="isSuspended"
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Blocked
                            </FormLabel>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={10}
                            className={classes.customSwitch}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isBlocked}
                                  onChange={this.handleChangeBlock}
                                  value="isBlocked"
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel
                              className={
                                classes.labelHorizontal +
                                " " +
                                classes.manageUserCardTitle
                              }
                            >
                              Inactivated
                            </FormLabel>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={10}
                            className={classes.customSwitch}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isInActivated}
                                  onChange={this.handleChangeInactivate}
                                  value="isInActivated"
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12}>
                            <div style={{ float: "right" }}>
                              <Button color="info" onClick={handleSubmit}>
                                Save
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </Formik>
        </Suspense>
      </>
    );
  }
}

ManageServiceProvider.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageServiceProvider);
