import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const DeliveryPartnerStyle = theme => ({
    ...regularFormsStyle,
    ...extendedTablesStyle,
    manageUserCardTitle: {
        color: "#0000007d",
    },
    deliveryPartnerDivSpacing: {
        marginBottom: "20px"
    },
    deliveryPartnerFieldDivSpacing: {
        margin: "0"
    },
    deliveryPartnerTitleDivSpacing: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& label": {
            padding: "0",
            fontWeight: "500"
        }
    },
    titleSpacing: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    titleIcon: {
        marginRight: "5px"
    },
    cardIconTitle: {
        color: "#3C4858",
        marginTop: "15px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "0px",
        textDecoration: "none"
    },
    DeliveryPartnerList: {
        "& th": {
            fontWeight: "500",
        }
    },
    floatRight: {
        float: 'right'
    }
});

export default DeliveryPartnerStyle;