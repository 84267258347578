import React from "react";
import ChartistGraph from "react-chartist";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import OfferIcon from "@material-ui/icons/LocalOffer";
import ReplyIcon from "@material-ui/icons/Reply";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg>
          <Card className={classes.cardHoverEffect}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <i className="fas fa-user" />
              </CardIcon>
              <div className={classes.cardContent}>
                <p className={classes.cardCategory}> Total Users </p>
                <h3 className={classes.cardTitle}>5000</h3>
              </div>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Link to="/admin/user-list" className={classes.linkColor}>
                  {" "}
                  <ArrowForwardIcon /> User List{" "}
                </Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg>
          <Card className={classes.cardHoverEffect}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <i className="fas fa-bars" aria-hidden="true" />
              </CardIcon>
              <div className={classes.cardContent}>
                <p className={classes.cardCategory}>Service Categories</p>
                <h3 className={classes.cardTitle}>30</h3>
              </div>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Link
                  to="/admin/service-categories-list"
                  className={classes.linkColor}
                >
                  {" "}
                  <ArrowForwardIcon /> Service Categories List{" "}
                </Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg>
          <Card className={classes.cardHoverEffect}>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <i className="fas fa-user" />
              </CardIcon>
              <div className={classes.cardContent}>
                <p className={classes.cardCategory}>Delivery Managers</p>
                <h3 className={classes.cardTitle}>750</h3>
              </div>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Link
                  to="/admin/delivery-partner-list"
                  className={classes.linkColor}
                >
                  <ArrowForwardIcon /> Delivery Managers List
                </Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg>
          <Card className={classes.cardHoverEffect}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <i className="fas fa-user" />
              </CardIcon>
              <div className={classes.cardContent}>
                <p className={classes.cardCategory}>Service Providers</p>
                <h3 className={classes.cardTitle}>200</h3>
              </div>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Link
                  to="/admin/service-provider-list"
                  className={classes.linkColor}
                >
                  <ArrowForwardIcon /> Service Providers List
                </Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg>
          <Card className={classes.cardHoverEffect}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <i className="fas fa-ticket-alt" />
              </CardIcon>
              <div className={classes.cardContent}>
                <p className={classes.cardCategory}>Customer Tickets</p>
                <h3 className={classes.cardTitle}>25</h3>
              </div>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Link
                  to="/admin/customer-service-list"
                  className={classes.linkColor}
                >
                  <ArrowForwardIcon /> Customer Ticket List
                </Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg>
          <Card className={classes.cardHoverEffect}>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <i className="fas fa-bars" />
              </CardIcon>
              <div className={classes.cardContent}>
                <p className={classes.cardCategory}> Total Orders</p>
                <h3 className={classes.cardTitle}>2000</h3>
              </div>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Link to="/admin/order-list" className={classes.linkColor}>
                  <ArrowForwardIcon /> Order List
                </Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={6} md={6}>
          <Card className={classes.cardHoverEffect}>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <i className="fas fa-ticket-alt" />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ fontWeight: "bold" }}
              >
                Latest Customer Tickets
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableData={[
                      [
                        <span style={{ fontWeight: "bold" }}> Ticket No </span>,
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          Ticket Type{" "}
                        </span>,
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          Ticket Description{" "}
                        </span>,
                        <span style={{ fontWeight: "bold" }}> Action </span>,
                      ],
                      [
                        "101",
                        "Query",
                        "Query",
                        <Link to="/admin/manage-customer-service">
                          <Button color="warning" size="sm">
                            {" "}
                            <ReplyIcon />{" "}
                          </Button>
                        </Link>,
                      ],
                      [
                        "102",
                        "Concern",
                        "Concern",
                        <Link to="/admin/manage-customer-service">
                          <Button color="warning" size="sm">
                            {" "}
                            <ReplyIcon />{" "}
                          </Button>
                        </Link>,
                      ],
                      [
                        "103",
                        "Concern",
                        "Concern",
                        <Link to="/admin/manage-customer-service">
                          <Button color="warning" size="sm">
                            {" "}
                            <ReplyIcon />{" "}
                          </Button>
                        </Link>,
                      ],
                      [
                        "104",
                        "Complain",
                        "Complain",
                        <Link to="/admin/manage-customer-service">
                          <Button color="warning" size="sm">
                            {" "}
                            <ReplyIcon />{" "}
                          </Button>
                        </Link>,
                      ],
                      [
                        "105",
                        "Query",
                        "Query",
                        <Link to="/admin/manage-customer-service">
                          <Button color="warning" size="sm">
                            {" "}
                            <ReplyIcon />{" "}
                          </Button>
                        </Link>,
                      ],
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6} md={6}>
          <Card className={classes.cardHoverEffect}>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <OfferIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ fontWeight: "bold" }}
              >
                Latest Promotion And Offers
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableData={[
                      [
                        <span style={{ fontWeight: "bold" }}> No </span>,
                        <span style={{ fontWeight: "bold" }}>
                          Promotion Types
                        </span>,
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          Promotion Activation{" "}
                        </span>,
                        <span style={{ fontWeight: "bold" }}> Action </span>,
                      ],
                      [
                        "1",
                        "SPUR50",
                        "Active",
                        <Link to="/admin/manage-promotion">
                          <Button color="warning" size="sm">
                            {" "}
                            <VisibilityIcon />{" "}
                          </Button>
                        </Link>,
                      ],
                      [
                        "2",
                        "SPUR10",
                        "Active",
                        <Link to="/admin/manage-promotion">
                          <Button color="warning" size="sm">
                            {" "}
                            <VisibilityIcon />{" "}
                          </Button>
                        </Link>,
                      ],
                      [
                        "3",
                        "SPUR15",
                        "Active",
                        <Link to="/admin/manage-promotion">
                          <Button color="warning" size="sm">
                            {" "}
                            <VisibilityIcon />{" "}
                          </Button>
                        </Link>,
                      ],
                      [
                        "4",
                        "SPUR25",
                        "Active",
                        <Button color="warning" size="sm">
                          {" "}
                          <VisibilityIcon />{" "}
                        </Button>,
                      ],
                      [
                        "5",
                        "SPUR5",
                        "Active",
                        <Link to="/admin/manage-promotion">
                          <Button color="warning" size="sm">
                            {" "}
                            <VisibilityIcon />{" "}
                          </Button>
                        </Link>,
                      ],
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={4}>
          <Card
            chart
            className={classes.cardHover + " " + classes.cardHoverEffect}
          >
            <CardHeader color="info" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="Refresh"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Change Date"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="transparent" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Daily Services</h4>
              {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p> */}
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={4}>
          <Card
            chart
            className={classes.cardHover + " " + classes.cardHoverEffect}
          >
            <CardHeader color="warning" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="Refresh"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Change Date"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="transparent" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Monthly Services</h4>
              {/* <p className={classes.cardCategory}>Last Campaign Performance</p> */}
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Updated 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={4}>
          <Card
            chart
            className={classes.cardHover + " " + classes.cardHoverEffect}
          >
            <CardHeader color="danger" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="Refresh"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Change Date"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="transparent" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Yearly Revenue </h4>
              {/* <p className={classes.cardCategory}>Last Campaign Performance</p> */}
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
